<template>
  <div v-if="products">
    <div>
      <h1 style="line-height: 3rem">
        <p>
          {{
            $t(
              `${
                hasFavorites ? "favourites.preferred" : "favourites.bestselling"
              }`
            )
          }}
        </p>
      </h1>
      <div class="text-body-2 default--text">
        <p>Sono stati trovati {{ products.length }} prodotti</p>
      </div>
    </div>
    <product-list-grid :products="products" />
  </div>
</template>
<script>
import ProductListGrid from "@/components/product/ProductListGrid.vue";

import ProductService from "~/service/productService";
import deliveryReactive from "~/mixins/deliveryReactive";
import { mapGetters } from "vuex";

export default {
  name: "ListPreferred",
  data() {
    return {
      products: []
    };
  },
  components: {
    ProductListGrid
  },
  mixins: [deliveryReactive],
  computed: {
    ...mapGetters({
      hasFavorites: "cart/hasFavorites"
    })
  },
  methods: {
    async addAllToCart() {},
    async fetchProducts() {
      let response = await ProductService.adv({
        layout: this.hasFavorites ? 29 : 26,
        limit: 50
      });
      this.products = response.products;
    }
  },
  mounted() {
    this.fetchProducts();
  }
};
</script>
